<template>
    <div class="title flex-sb" :style="`background:${parameter.bg?parameter.bg:'#fff'}`">
        <div class="title_l font23 color-fff">{{parameter.text}}</div>
        <div v-if="showmore" class="title_r font19 color-44" style="cursor: pointer;" @click="gopage(parameter.link)">更多 <span class="font21">>></span></div>
    </div>
</template>

<script>
export default {
    props:{
        parameter:{
            type:Object,
            required:true
        },
		showmore:{
			type:Boolean,
			default:true
		}
    },
	methods:{
		gopage(page){
			console.log(this.parameter)
			this.$router.push(page)
		}
	}
}
</script>

<style scoped lang="scss">
.title {
    width: 100%;
    background: #EDEDED;
    border-bottom: 0.01rem solid #3D7ED0;
	min-height: 0.55rem;
    .title_l {
        padding: .12rem .79rem .12rem .37rem;
        background: url('../assets/home/title_bg.png') no-repeat;
        background-size: 100% 100%;
    }
    .title_r{
        display: flex;
        align-items: center;
        padding-right: .12rem;   
        font-weight: 500;
    }
}
</style>