<template>
	<div class="gk">
		<div class="gk_title">
			<div class="gk_title_l" style="font-size: 0.22rem;">{{name}}</div>
			<!-- <div class="gk_title_r font19">更多 >></div> -->
		</div>
		<ul class="pic_text">
			<li v-for="(item, index) in picTextData" :key="index" @click="gopath(item)" style="cursor: pointer;">
				<img :src="item.src" alt="">
				<div class="font24" style="font-size: 0.16rem;width: fit-content;">{{ item.name }}</div>
			</li>
			<!-- <div class="rightimg">
				<img src="../../../assets/tazjt.png" alt="" />
			</div> -->
		</ul>
		<div class="line"></div>
		<div class="gk_bottom">
			<div class="laba_title">
				<img src="@/assets/home/xlb.png" alt="">
				<span class="font23 fontweight600" style="font-size: 0.18rem;">通知公告</span>
			</div>
			<div style="display: flex;flex-direction: column;justify-content: space-between;height: 88%;">
				<ul>
					<li class="font19 ggtitle" v-for="(item, index) in gkData" :key="index"
						:class="{ 'first-item': index === 0 }">
						<div class="dian"></div>
						<span ref="contents" class="font19 color-333 fontweight500 content"
							:style="getContentStyle(index)" @click="godetail(item)">{{ item.name }}</span>
					</li>
				</ul>
				<div class="more font19 color-44" style="text-align: right;cursor: pointer;font-size: 0.14rem;"
					@click="golist">更多>></div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				name: '',
				picTextData: [{
					src: require('@/assets/home/pic_text1.png'),
					name: '政协领导',
					path: '/secondList?id=32&name=政协概况>政协领导'
				}, {
					src: require('@/assets/home/pic_text2.png'),
					name: '机构设置',
					path: '/secondList?id=16&name=机构设置'
				}, {
					src: require('@/assets/home/pic_text3.png'),
					name: '委员名录',
					path: '/secondList?id=17&name=政协概况>委员名录'
				}, {
					src: require('@/assets/home/pic_text4.png'),
					name: '规章制度',
					path: '/secondList?id=18&name=规章制度'
				}, {
					src: require('@/assets/home/pic_text5.png'),
					name: '预决算公开',
					path: '/secondList?id=31&name=预决算公开'
				}, ],
				gkData: [], // 你的数据数组
				maxLinesTotal: 4, // UL元素最多可以显示的行数
				linesUsedForFirstItem: 0, // 第一个条目使用的行数
			}
		},
		created() {
			this.$post({
				url: '/api/classify/index',
				params: {
					type: 1,
					p: 1,
					size: 9999
				}
			}).then((res) => {
				res.list.forEach((item, index) => { //获取模块标题中文
					if (item.id == 2) {
						this.name = item.name
					}
				})
			})

			this.$post({ //获取通知公告
				url: '/api/news/index',
				params: {
					id: 14,
					p: 1,
					size: 5
				}
			}).then((res) => {
				this.gkData = res.list
				this.$nextTick(() => {
					// 更新列表中的剩余行数
					this.updateLineCounts();
				});
			})
		},
		watch: {
			// 当数据更新时，重新计算行数
			gkData() {
				this.updateLineCounts();
			}
		},
		mounted() {

		},
		methods: {
			getContentStyle(index) {
				if (index === 0) {
					// 第一个条目不限制行数
					return {};
				}
				// 后续条目限制行数为剩余可用的行数
				return {
					'-webkit-line-clamp': this.maxLinesTotal - this.linesUsedForFirstItem,
					'display': '-webkit-box',
					'overflow': 'hidden',
				};
			},
			updateLineCounts() {
				// 确保DOM已更新
				this.$nextTick(() => {
					const contentElements = this.$refs.contents;
					let linesRemaining = this.maxLinesTotal;

					for (let i = 0; i < contentElements.length; i++) {
						if (linesRemaining > 0) {
							// 计算当前元素占用的行数
							let linesUsed = this.calculateLineCount(contentElements[i]);
							console.log(linesUsed)
							if (i === 0) {
								this.linesUsedForFirstItem = linesUsed; // 更新第一个元素占用的行数
							}

							if (linesUsed > linesRemaining) {
								// 如果当前元素占用的行数超过了剩余行数
								this.setLineClamp(contentElements[i], linesRemaining);
								linesRemaining = 0;
							} else {
								// 如果没有超过，从剩余行数中减去当前元素占用的行数
								linesRemaining -= linesUsed;
								this.setLineClamp(contentElements[i], linesUsed);
							}
						} else {
							// 如果没有剩余行数，设置为显示省略号
							this.setLineClamp(contentElements[i], 1);
						}
					}
				});
			},
			// 设置元素的行数限制
			setLineClamp(element, lines) {
				element.style.webkitLineClamp = String(lines);
				element.style.display = '-webkit-box';
				element.style.overflow = 'hidden';
				element.style.webkitBoxOrient = 'vertical';
			},
			calculateLineCount(element) {
				const style = window.getComputedStyle(element);
				let lineHeight = parseInt(style.lineHeight);
				if (isNaN(lineHeight)) {
					// 如果 lineHeight 无法解析，使用 fontSize 作为备用，并乘以常见的系数
					const fontSize = parseInt(style.fontSize);
					lineHeight = fontSize * 1.5; // 常见的默认行高系数为 1.2
				}
				const height = element.clientHeight;
				// 计算并返回行数
				return Math.ceil(height / lineHeight);
			},
			godetail(item) {
				this.$router.push('/secondList?id=14&name=通知公告&detail_id=' + item.id)
			},
			golist() {
				this.$router.push('/secondList?id=14&name=通知公告')
			},
			gopath(item) {
				this.$router.push(item.path)
			}
		}
	}
</script>


<style lang="scss" scoped>
	.gk {
		width: 23.5%;

		.gk_title {
			width: 100%;
			background: #EDEDED;
			border-bottom: 0.01rem solid #3D7ED0;
			padding: .12rem .12rem .12rem 0;
			display: flex;
			justify-content: space-between;
			position: relative;

			.gk_title_l {
				color: #3D7ED0;
				padding-left: .23rem;
			}

			.gk_title_r {
				display: flex;
				align-items: center;
				font-weight: 500;
			}
		}

		.gk_title::after {
			content: '';
			display: block;
			position: absolute;
			width: .14rem;
			height: 100%;
			background: #3D7ED0;
			left: 0;
			top: 0;
		}

		.pic_text {
			padding: .18rem .18rem .10rem .18rem;
			box-sizing: border-box;
			background: #fff;
			border-left: 0.01rem solid #DDDDDD;
			border-right: 0.01rem solid #DDDDDD;
			position: relative;
			.rightimg{
				position: absolute;
				right: -2.63rem;
				top:0;
				img{
					width: 2.6rem;
					height: 1.58rem;
					cursor: pointer;
				}
			}
			li {
				width: 90.2%;
				margin: 0 auto;
				padding: .1rem 0;
				display: flex;
				align-items: center;
				padding-left: 0.3rem;
				background: linear-gradient(180deg, #4F99F2 0%, #2268C3 100%);
				color: #fff;
				border-radius: .05rem .05rem .05rem .05rem;
				margin-bottom: .11rem;

				img {
					width: .25rem;
					height: .25rem;
					margin-right: .26rem;
				}
			}
		}

		.pic_text:nth-last-child(1) {
			margin: 0;
		}

		.line {
			border-bottom: .01rem dashed #E1E1E1;
			width: 90.2%;
			margin: 0 auto;
		}

		.gk_bottom {
			background: #fff;
			padding: .20rem .36rem .30rem .16rem;
			border: .01rem solid #DDDDDD;
			border-top: none;
			height: 1.98rem;
			
			.laba_title {
				display: flex;
				margin-bottom: .1rem;
				display: flex;
				align-items: center;

				img {
					width: .26rem;
					height: .27rem;
				}

				span {
					color: #252525;
				}
			}

			ul {
				max-height: 77%;
				/* 以1em作为每行的高度基准 */
				overflow: hidden;

				li {
					display: flex;
					align-items: center;
					margin-bottom: .08rem;
					overflow: hidden;

					.dian {
						width: 4px;
						height: 4px;
						background: #2C65AF;
						margin-right: .10rem;
					}


					.content {
						width: 100%;
						font-size: 0.16rem;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}

				}

				.first-item .content {
					/* 第一个条目的内容不限制显示行数 */
					-webkit-line-clamp: initial;
				}
			}

			.more {
				float: right;
				font-size: .19rem;
				color: #444;
			}
		}
	}
	.ggtitle{
		cursor: pointer;
	}
	.ggtitle:hover{
		color: #2C65AF;
	}
</style>